import React, { memo } from "react";
import { SketchPicker } from "react-color";
import OutsideClickHandler from "react-outside-click-handler";

const ColorPicker = function ({
  backgroundColor,
  updateBackgroundColor,
  closeColorPicker,
}) {
  const [color, setColor] = React.useState(backgroundColor);
  const onChangeComplete = React.useCallback(
    (newColor) => {
      setColor(newColor.hex);
      updateBackgroundColor(backgroundColor, newColor.hex);
    },
    [backgroundColor]
  );

  const onColorChange = React.useCallback((newColor) => {
    setColor(newColor.hex);
  }, []);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        closeColorPicker();
      }}
    >
      <SketchPicker
        color={color}
        onChange={onColorChange}
        onChangeComplete={onChangeComplete}
      />
    </OutsideClickHandler>
  );
};

export default memo(ColorPicker);
