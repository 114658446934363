import React, { useState } from "react";
import ColorSelectorDropdown from "../ColorSelectorDropdown";

const ColorsPreview = (props) => {
  const { colors } = props;

  const [primaryColorIndex, setPrimaryColorIndex] = useState(0);
  const [secondaryColorIndex, setSecondaryColorIndex] = useState(1);
  const [accentColorIndex, setAccentColorIndex] = useState(2);

  const primaryColor =
    colors[primaryColorIndex < colors.length ? primaryColorIndex : 0].hex;
  const secondaryColor =
    colors[secondaryColorIndex < colors.length ? secondaryColorIndex : 0].hex;
  const accentColor =
    colors[accentColorIndex < colors.length ? accentColorIndex : 0].hex;

  return (
    <>
      <div
        className="settings-area  gy-3 mt-3"
        style={{
          margin: "2rem",
          display: "flex",
          flexWrap: "wrap",
          gap: 20,
        }}
      >
        <ColorSelectorDropdown
          setSelectedIndex={setPrimaryColorIndex}
          title="Primary color"
          colors={colors}
          selectedIndex={
            primaryColorIndex < colors.length ? primaryColorIndex : 0
          }
        />

        <ColorSelectorDropdown
          setSelectedIndex={setSecondaryColorIndex}
          title="Secondary color"
          colors={colors}
          selectedIndex={
            secondaryColorIndex < colors.length ? secondaryColorIndex : 0
          }
        />

        <ColorSelectorDropdown
          setSelectedIndex={setAccentColorIndex}
          title="Accent color"
          colors={colors}
          selectedIndex={
            accentColorIndex < colors.length ? accentColorIndex : 0
          }
        />
      </div>
      <div className="row flex justify-content-center">
        <div
          className=" col-md-6 card align-items-center justify-content-evenly apply-primary-color mt-4"
          style={{ backgroundColor: primaryColor, height: "20rem" }}
        >
          <h3
            className="p-1 apply-secondary-color"
            style={{ color: secondaryColor }}
          >
            {" "}
            HueHive template to visualize palette{" "}
          </h3>
          <p
            className="p-1 apply-accent-color-paragraph"
            style={{ color: accentColor }}
          >
            {" "}
            HueHive template to visualize palette{" "}
          </p>
          <div
            className="p-3 apply-accent-color btn"
            //style="background-color: <%= colors[2][:hex]%>; width: 5rem"
          >
            {" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default ColorsPreview;
