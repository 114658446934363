import React from "react";
import Accessibility from "../Accessibility";
import ColorsPreview from "./ColorsPreview";

const Tabs = (props) => {
  const { description, colors, isEditMode } = props;

  const renderPreview = () => {
    return (
      <div
        class="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        {description && (
          <pre
            class="mt-5 mb-2 paragraph tw-items-start"
            style={{
              background: "#f9f9fb",
              border: "1px solid #ccc",
              padding: "1rem",
              margin: "0px",
              display: "flex",
              flexDirection: "row",
              whiteSpace: "pre-wrap",
              fontSize: "1.2rem",
            }}
          >
            <img
              src="https://developer.mozilla.org/static/media/chatgpt.ec5d4e8331e69d951bde.svg"
              width={20}
              height={20}
              style={{ marginRight: "1rem" }}
            ></img>
            <code
              dangerouslySetInnerHTML={{
                __html: description.replaceAll('^"|"$', ""),
              }}
            ></code>
          </pre>
        )}
      </div>
    );
  };

  return (
    <>
      {isEditMode ? (
        <>
          <ul class="nav nav-tabs mt-4 " role="tablist">
            {/* <li class="nav-item" role="presentation">
              <button
                class="nav-link active "
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                <h5>Description</h5>
              </button>
            </li> */}
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="preview-tab"
                data-bs-toggle="tab"
                data-bs-target="#preview"
                type="button"
                role="tab"
                aria-controls="preview"
              >
                <h5>Preview</h5>
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="accessibility-tab"
                data-bs-toggle="tab"
                data-bs-target="#accessibility"
                type="button"
                role="tab"
                aria-controls="accessibility"
              >
                <h5>Accessibility</h5>
              </button>
            </li>
          </ul>
          <div class="tab-content " id="palettesTabContent">
            {/* {renderPreview()} */}
            <div
              class="tab-pane fade show active"
              id="preview"
              role="tabpanel"
              aria-labelledby="preview-tab"
            >
              <ColorsPreview colors={colors}></ColorsPreview>
            </div>
            <div
              class="tab-pane fade"
              id="accessibility"
              role="tabpanel"
              aria-labelledby="accessibility-tab"
            >
              <Accessibility colors={colors}></Accessibility>
            </div>
          </div>
        </>
      ) : (
        renderPreview()
      )}
    </>
  );
};

export default Tabs;
