import PropTypes from "prop-types";
import React, { useState } from "react";
import { contrast } from "../../../utils/color-utils";
import ColorSelectorDropdown from "./ColorSelectorDropdown";
import { getTextColor } from "../../../utils/color-utils";
// https://huehive.co/color_palettes/502
function colorForContrastRatio(ratio) {
  if (ratio >= 7) {
    return "#006400";
  } else if (ratio >= 4.5) {
    return "#808000";
  } else if (ratio >= 3) {
    return "#FF7F50";
  } else {
    return "#8B0000";
  }
}
function ratioStr(ratio) {
  return (Math.round(ratio * 100) / 100).toFixed(2) + ":1";
}

function highestContrastColors(colors) {
  let highestContrast = 0;
  let highestContrastColorsIndex = [];
  for (let i = 0; i < colors.length; i++) {
    for (let j = i + 1; j < colors.length; j++) {
      const ratio = contrast(colors[i].hex, colors[j].hex);
      if (ratio > highestContrast) {
        highestContrast = ratio;
        highestContrastColorsIndex = [i, j];
      }
    }
  }
  return highestContrastColorsIndex;
}
const Accessibility = (props) => {
  const { colors } = props;
  const hcColorsIndex = highestContrastColors(colors);
  const [backgroundColorIndex, setBackgroundColorIndex] = useState(
    hcColorsIndex[0]
  );
  const [textColorIndex, setTextColorIndex] = useState(hcColorsIndex[1]);
  const backgroundColor =
    colors[backgroundColorIndex < colors.length ? backgroundColorIndex : 0].hex;
  const textColorHex =
    colors[textColorIndex < colors.length ? textColorIndex : 0].hex;
  const ratio = contrast(backgroundColor, textColorHex);

  return (
    <div>
      <div
        class="p-2 d-flex flex-row justify-content-between flex-wrap "
        style={{ rowGap: "2rem" }}
      >
        <div class="d-flex flex-row align-items-center">
          <div>
            <ColorSelectorDropdown
              setSelectedIndex={setBackgroundColorIndex}
              title="Background Color"
              colors={colors}
              selectedIndex={
                backgroundColorIndex < colors.length ? backgroundColorIndex : 0
              }
            />
          </div>
          <div class="ms-3">
            <ColorSelectorDropdown
              setSelectedIndex={setTextColorIndex}
              title="Text Color"
              colors={colors}
              selectedIndex={
                textColorIndex < colors.length ? textColorIndex : 0
              }
            />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <div
            style={{
              backgroundColor: colorForContrastRatio(1),
              color: getTextColor(colorForContrastRatio(8)),
            }}
            class="p-2"
          >
            {" "}
            Very Poor(&lt;3){" "}
          </div>
          <div
            style={{
              backgroundColor: colorForContrastRatio(4),
              color: getTextColor(colorForContrastRatio(8)),
            }}
            class="p-2"
          >
            {" "}
            Poor (3 to 4.5){" "}
          </div>
          <div
            style={{
              backgroundColor: colorForContrastRatio(6),
              color: getTextColor(colorForContrastRatio(8)),
            }}
            class="p-2"
          >
            {" "}
            Good (4.5 to 7){" "}
          </div>
          <div
            style={{
              backgroundColor: colorForContrastRatio(8),
              color: getTextColor(colorForContrastRatio(8)),
            }}
            class="p-2"
          >
            {" "}
            Very good (&gt;7){" "}
          </div>
        </div>
        <div class="ms-3 d-flex flex-row align-items-center justify-content-center">
          <h2 class="pe-2">Contrast Ratio</h2>
          <div
            class="p-3 d-flex justify-content-center align-items-center font-weight-bold"
            style={{
              backgroundColor: colorForContrastRatio(ratio),
              color: "white",
            }}
          >
            <span class="font-weight-bold">{ratioStr(ratio)}</span>
          </div>
        </div>
      </div>
      <hr />

      <div
        class="p-4 d-flex align-items-center flex-column justify-content-center "
        style={{ backgroundColor: backgroundColor }}
      >
        <h1 class="p-1" style={{ color: textColorHex }}>
          Large sample text - heading 1
        </h1>
        <h2 class="p-1" style={{ color: textColorHex }}>
          Large sample text - heading 2
        </h2>
        <h3 class="p-1" style={{ color: textColorHex }}>
          Large sample text - heading 3
        </h3>
        <p class="p-1" style={{ color: textColorHex }}>
          Small sample text - paragraph
        </p>
      </div>
    </div>
  );
};

Accessibility.propTypes = {
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

export default Accessibility;
