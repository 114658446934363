import ColorPicker from "./ColorPicker";
import style from "./ColorPalette.module.css";
import React, { memo } from "react";

const Color = function (props) {
  const {
    color,
    closeColorPicker,
    copyToClipboard,
    setActiveColorPicker,
    updateColors,
    toggleColorLock,
    textColor,
    activeColorPicker,
    isEditMode,
  } = props;
  const [copyTooltipText, setCopyTooltipText] = React.useState();

  return (
    <div
      style={{ backgroundColor: color.hex }}
      className={"palette__color   " + style.colorWrapper}
    >
      <div className={style.colorDetail + " mt-3"} style={{ color: textColor }}>
        <span className={`color-hex-text `}>{color.hex}</span>
        <span className={`color-name-text ${style.colorName}`}>
          {color.name}
        </span>
      </div>
      <div
        className={
          " flex-column m-5 palette__actions align-items-start " +
          style.iconWrapper
        }
      >
        <div
          className={style.icon}
          onClick={() => {
            copyToClipboard(color.hex);
            setCopyTooltipText("Copied!");
            setTimeout(() => {
              setCopyTooltipText(null);
            }, 400);
          }}
        >
          {copyTooltipText && (
            <div className={style.tooltip}> {copyTooltipText}</div>
          )}
          <i className="fa fa-copy "></i>
        </div>
        {isEditMode && (
          <>
            {color.isLocked ? (
              <div
                className={style.icon}
                onClick={() => {
                  toggleColorLock(color.hex);
                }}
              >
                <div className={style.tooltip}> Unlock the color </div>
                <i className="fa fa-lock "></i>
              </div>
            ) : (
              <div
                className={style.icon}
                onClick={() => {
                  toggleColorLock(color.hex);
                }}
              >
                <div className={style.tooltip}> Lock the color </div>
                <i className="fa fa-unlock "></i>
              </div>
            )}
            <div
              className={style.icon}
              onClick={() =>
                setActiveColorPicker({ hex: color.hex, action: "create" })
              }
            >
              {/* <div className={style.tooltip}> </div> */}
              <i className="fa fa-plus"></i>
            </div>
            <div
              className={style.icon}
              onClick={() => updateColors(color.hex, null, "delete")}
            >
              {/* <div className={style.tooltip}> </div> */}
              <i className="fa fa-archive"></i>
            </div>
            <div
              className={style.icon}
              onClick={() =>
                setActiveColorPicker({ hex: color.hex, action: "update" })
              }
            >
              <div className={style.tooltip}> Pick a color </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-droplet-half"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21.8C7.69.295 8 0 8 0c.109.363.234.708.371 1.038.812 1.946 2.073 3.35 3.197 4.6C12.878 7.096 14 8.345 14 10a6 6 0 0 1-12 0C2 6.668 5.58 2.517 7.21.8zm.413 1.021A31.25 31.25 0 0 0 5.794 3.99c-.726.95-1.436 2.008-1.96 3.07C3.304 8.133 3 9.138 3 10c0 0 2.5 1.5 5 .5s5-.5 5-.5c0-1.201-.796-2.157-2.181-3.7l-.03-.032C9.75 5.11 8.5 3.72 7.623 1.82z"
                />
                <path
                  fillRule="evenodd"
                  d="M4.553 7.776c.82-1.641 1.717-2.753 2.093-3.13l.708.708c-.29.29-1.128 1.311-1.907 2.87l-.894-.448z"
                />
              </svg>
            </div>
          </>
        )}
      </div>
      {color.hex === activeColorPicker.hex && (
        <div className={style.picker}>
          <ColorPicker
            backgroundColor={color.hex}
            updateBackgroundColor={(hex, newHex) =>
              updateColors(hex, newHex, activeColorPicker.action)
            }
            closeColorPicker={closeColorPicker}
          />
        </div>
      )}
    </div>
  );
};

export default memo(Color);
