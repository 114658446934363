import ReactOnRails from "react-on-rails";

import Accessibility from "../bundles/application/components/Accessibility";
import ColorPalette from "../bundles/application/components/ColorPalette/ColorPaletteServer";


ReactOnRails.register({
  Accessibility,
  ColorPalette,
});
