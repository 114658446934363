import React, { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ColorPalette.module.css";
import Tabs from "./Tabs";
import Color from "./Color";
import {
  getColorNameUsingHex,
  getTextColor,
} from "../../../../utils/color-utils";
import html2canvas from "html2canvas";
import ColorPalette from "./ColorPaletteServer";

// TODO: moving it inline as server is failing when precompling. Need to debug.
const IconButton = ({
  name,
  bootstrapIconCode,
  onClick,
  toolTipText,
  children,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className="btn"
      style={{ marginRight: ".8rem" }}
      type="button"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={toolTipText}
    >
      <i class={bootstrapIconCode}></i> {children}
    </button>
  );
};

function getRandomColor() {
  const availableCharacters = "0123456789ABCDEF";
  const availableCharacterLength = availableCharacters.length;

  let color = "#";

  for (let i = 0; i < 6; i++) {
    color +=
      availableCharacters[Math.floor(Math.random() * availableCharacterLength)];
  }

  return color;
}

const createInitialColorState = (colors) => {
  return {
    tooltipText: "Copy hex",
    activeColorPicker: { hex: null, action: null },
    colors: colors.map((color) => {
      return {
        ...color,
        isLocked: false,
      };
    }),
  };
};

const reducer = (state, payload) => {
  switch (payload.type) {
    case "SET_TOOLTIP_TEXT":
      return { ...state, tooltipText: payload.tooltipText };
    case "SET_COLOR_LOCK":
      return {
        ...state,
        colors: state.colors.map((color) => {
          if (color.hex === payload.colorHex) {
            return {
              ...color,
              isLocked: !color.isLocked,
            };
          } else {
            return color;
          }
        }),
      };
    case "UPDATE_COLOR":
      if (payload.action === "create") {
        const colors = [];
        state.colors.forEach((color) => {
          colors.push(color);
          if (color.hex === payload.hex) {
            colors.push({
              hex: payload.newHex,
              isLocked: false,
              name: payload.name || "",
            });
          }
        });
        return {
          ...state,
          colors,
        };
      }
      if (payload.action === "update") {
        return {
          ...state,
          colors: state.colors.map((color) => {
            if (payload.hex === color.hex) {
              return {
                ...color,
                hex: payload.newHex,
                name: payload.name || "",
              };
            }
            return color;
          }),
        };
      }
      if (payload.action === "delete") {
        return {
          ...state,
          colors: state.colors.filter((color) => color.hex !== payload.hex),
        };
      }
      return state;
    case "UPDATE_COLORS":
      return {
        ...state,
        colors: payload.colors,
      };

    case "SET_ACTIVE_COLOR_PICKER":
      return {
        ...state,
        activeColorPicker: payload.activeColorPicker,
      };
    default:
      return state;
  }
};

const ColorPalettes = (props) => {
  const { description, currentUser, userQuery } = props;
  const exportRef = useRef();
  const [state, dispatch] = React.useReducer(
    reducer,
    createInitialColorState(props.colors)
  );
  const [isEditMode, setIsEditMode] = React.useState(false);

  const savePaletteFormRef = useRef();

  const { tooltipText, activeColorPicker, colors } = state;

  const copyToClipboard = useCallback((hex) => {
    navigator.clipboard.writeText(hex);
    dispatch({ type: "SET_TOOLTIP_TEXT", tooltipText: "Copied!" });
    setTimeout(() => {
      dispatch({ type: "SET_TOOLTIP_TEXT", tooltipText: tooltipText });
    }, 1500);
  }, []);

  const toggleColorLock = useCallback((colorHex) => {
    dispatch({ type: "SET_COLOR_LOCK", colorHex: colorHex });
  }, []);

  const updateNameOfColorUsingHex = useCallback(async (hex) => {
    try {
      const colorName = await getColorNameUsingHex(hex);
      dispatch({
        type: "UPDATE_COLOR",
        hex: hex,
        newHex: hex,
        action: "update",
        name: colorName,
      });
    } catch (error) {}
  }, []);

  const updateColors = useCallback((hex, newHex, action) => {
    dispatch({ type: "UPDATE_COLOR", hex, newHex: newHex, action });
    dispatch({
      type: "SET_ACTIVE_COLOR_PICKER",
      activeColorPicker: { hex: newHex, action },
    });
    // getting name fo new hex and updating the ui async
    updateNameOfColorUsingHex(newHex);
  }, []);

  const setActiveColorPicker = useCallback((data) => {
    dispatch({
      type: "SET_ACTIVE_COLOR_PICKER",
      activeColorPicker: data,
    });
  }, []);

  const closeColorPicker = () => {
    dispatch({
      type: "SET_ACTIVE_COLOR_PICKER",
      activeColorPicker: { hex: null, action: null },
    });
  };

  const shuffleColors = useCallback(() => {
    const shuffledColors = colors.map((color) => {
      if (color.isLocked) {
        return color;
      }
      return {
        ...color,
        hex: getRandomColor(),
      };
    });
    dispatch({
      type: "UPDATE_COLORS",
      colors: shuffledColors,
    });
    // async update names of new colors
    shuffledColors.forEach((color) => {
      if (!color.isLocked) {
        updateNameOfColorUsingHex(color.hex);
      }
    });
  }, [colors]);

  const exportAsImage = async (el, imageFileName) => {
    const canvas = await html2canvas(el);
    const link = document.createElement("a");
    link.download = imageFileName;
    link.href = canvas.toDataURL();
    link.click();
    link.delete;
  };

  return (
    <>
      <div ref={exportRef} className={`palette__colors d-flex`}>
        {colors.map((color) => (
          <Color
            key={color.hex}
            color={color}
            closeColorPicker={closeColorPicker}
            updateColors={updateColors}
            toggleColorLock={toggleColorLock}
            textColor={getTextColor(color.hex)}
            copyToClipboard={copyToClipboard}
            setActiveColorPicker={setActiveColorPicker}
            activeColorPicker={activeColorPicker}
            isEditMode={isEditMode}
          />
        ))}
      </div>
      <div
        className={`d-flex flex-row justify-content-between align-items-center border p-3`}
      >
        <p class="h3">{userQuery}</p>
        <div>
          {!isEditMode ? (
            <button
              onClick={() => setIsEditMode(true)}
              className={`primary-button ${style.modifyManually}`}
            >
              <i class="fa fa-edit"></i> &nbsp; Modify manually
            </button>
          ) : (
            <div className="d-flex flex-row ">
              <IconButton
                onClick={() => {
                  currentUser
                    ? savePaletteFormRef.current?.submit()
                    : (window.location.href = "/users/sign_in");
                }}
                bootstrapIconCode={"fa fa-save"}
              >
                <span className="d-none d-md-inline ml-1">Save</span>
              </IconButton>
              <IconButton
                onClick={shuffleColors}
                bootstrapIconCode={"fa fa-random"}
              >
                <span className="d-none d-md-inline ml-1">
                Suffle
                </span>
              </IconButton>
              <IconButton
                onClick={() =>
                  exportAsImage(exportRef.current, userQuery + ".png")
                }
                bootstrapIconCode={"fa fa-download"}
              >
                <span className="d-none d-md-inline">Download</span>
              </IconButton>
            </div>
          )}
        </div>
      </div>
      <Tabs colors={colors} description={description} isEditMode={isEditMode} />
      <form
        class="simple_form new_color_palette"
        id="new_color_palette"
        novalidate="novalidate"
        action="/color_palettes"
        accept-charset="UTF-8"
        className="d-none"
        method="post"
        ref={savePaletteFormRef}
      >
        <input
          type="hidden"
          name="authenticity_token"
          value="M6_fP5ViEmXOftlk6qra8x1KgaBw6kqKyLyCqvVjhkZfYR_oGuZyVFyoNLneYqF-9Rcb6QG1ZdUNp2dPXrD_-A"
          autocomplete="off"
        />
        <div class="d-none">
          {colors.map((color, index) => (
            <div key={color.hex}>
              <div class="mb-3 string required color_palette_colors_hex">
                <label
                  class="form-label string required"
                  for="color_palette_colors_attributes_0_hex"
                >
                  Hex
                  <abbr title="required">*</abbr>
                </label>
                <input
                  class="form-control is-valid string required"
                  required="required"
                  aria-required="true"
                  type="text"
                  value={color.hex}
                  name={`color_palette[colors_attributes][${index}][hex]`}
                />
              </div>
              <div class="mb-3 string optional color_palette_colors_name">
                <label
                  class="form-label string optional"
                  for="color_palette_colors_attributes_0_name"
                >
                  Name
                </label>
                <input
                  class="form-control is-valid string optional"
                  type="text"
                  value={color.name}
                  name={`color_palette[colors_attributes][${index}][name]`}
                />
              </div>
            </div>
          ))}
        </div>
        <div class="d-flex flex-row align-items-center justify-content-center row mt-4">
          <div
            class=" string required color_palette_name col-10 d-flex justify-content-center flex-row align-items-center"
            id="saveBtnWrapperId"
          >
            <label
              class="form-label string required d-none"
              for="color_palette_name"
            >
              Name <abbr title="required">*</abbr>
            </label>
            <input
              class="form-control is-valid string required"
              required="required"
              aria-required="true"
              type="text"
              value={userQuery}
              name="color_palette[name]"
            />
          </div>
          <div class="col-2 d-flex justify-content-center flex-row">
            <input
              type="submit"
              name="commit"
              value="Save"
              class="btn btn-primary btn-lg col-12"
              data-disable-with="Save"
            />
          </div>
        </div>
      </form>
    </>
  );
};

ColorPalettes.propTypes = {
  colors: PropTypes.array.isRequired, // this is passed from the Rails view
};

export default ColorPalettes;
