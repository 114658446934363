import React from "react";
import { Dropdown } from "react-bootstrap";

export default function ColorSelectorDropdown({
  title,
  selectedIndex,
  setSelectedIndex,
  colors,
}) {
  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-color-selector"
        variant="light"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: "200px",
        }}
      >
        <div class="d-flex flex-row justify-content-between align-items-center w-100 h5">
          <span>{title}</span>
          <span
            class="p-1 ms-4 me-2"
            style={{
              backgroundColor: colors[selectedIndex].hex,
              borderRadius: "50%",
              height: "2rem",
              width: "2rem",
              display: "inline-block",
              border: ".2rem solid black",
            }}
          ></span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ minWidth: "200px" }}>
        {colors.map((color, index) => (
          <Dropdown.Item
            onClick={() => setSelectedIndex(index)}
            value={color.hex}
          >
            <div class="d-flex flex-row justify-content-between align-items-center h5 w-100">
              <span>{color.name}</span>
              <span
                class="p-1 ms-4"
                style={{
                  backgroundColor: color.hex,
                  borderRadius: "50%",
                  height: "2rem",
                  width: "2rem",
                  display: "inline-block",
                  border: ".2rem solid black",
                }}
              ></span>
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
